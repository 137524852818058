import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { useDrawer } from '../../context/drawerContext'; // Assicurati che il percorso sia corretto

import './SideDrawer.css';

const SideDrawer = props => {
  const { isDrawerOpen /*, closeDrawer*/ } = useDrawer();

  // Utilizza isDrawerOpen per controllare la visibilità del SideDrawer se necessario
  // Se non stai utilizzando closeDrawer in questo componente, puoi ometterlo

  const content = (
    <CSSTransition
      in={props.show || isDrawerOpen} // Usa isDrawerOpen qui se vuoi controllare la visibilità in base allo stato del contesto
      timeout={200}
      classNames="slide-in-left"
      mountOnEnter
      unmountOnExit
    >
      <aside className="side-drawer" onClick={props.onClick}>{props.children}</aside>
    </CSSTransition>
  );

  return ReactDOM.createPortal(content, document.getElementById('drawer-hook'));
};

export default SideDrawer;
