import React, { Suspense, useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';
import MainForm from './user/pages/MainForm';
import MainNavigation from './shared/components/Navigation/MainNavigation';
// import DownloadIni from './user/pages/DownloadIni';
// import Congratulazioni from './user/pages/Congratulazioni';
//import DownloadStart from './user/pages/DownloadStart';
//import Auth from './user/pages/Auth';
// import Faq from './user/pages/Faq';
// import Analytics from './user/pages/Analytics';
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';

const Faq = React.lazy(()=> import ('./user/pages/Faq'));
const Congratulazioni = React.lazy(()=> import ('./user/pages/Congratulazioni'));
const DownloadIni = React.lazy(()=> import ('./user/pages/DownloadIni'));
const DownloadStart = React.lazy(()=> import ('./user/pages/DownloadStart'));
const Auth = React.lazy(()=> import ('./user/pages/Auth'));
const Analytics = React.lazy(()=> import ('./user/pages/Analytics'));

const App = () => {
  const { token, login, logout, userId } = useAuth();
  const auth = useContext(AuthContext);
  const specificUserId = '65c606ad37d4de1254d54b86';

  let routes;

  if (token) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <MainForm />
        </Route>
        {userId === specificUserId && (
          <Route path="/auth">
          <Auth />
        </Route>
        )}
        <Route path="/downloads" exact>
          <DownloadIni />
        </Route>
        <Route path="/faq" exact>
          <Faq />
        </Route>
        {/* <Route path="/places/new" exact>
          <NewPlace />
        </Route> */}
        <Route path="/downloads/:usedId" exact>
          <DownloadStart />
        </Route>
        <Route path="/analytics" exact>
          <Analytics />
        </Route>
        {/* <Route path="/places/:placeId">
          <UpdatePlace />
        </Route> */}
        <Route path="/congratulazioni" exact>
          <Congratulazioni />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact>
          <MainForm />
        </Route>
        <Route path="/auth">
          <Auth />
        </Route>
        <Route path="/faq" exact>
          <Faq />
        </Route>
        <Route path="/congratulazioni" exact>
          <Congratulazioni />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
     value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout
      }}    >
      <Router>
        <MainNavigation />
        <main><Suspense fallback={
          <div className='center'>
            <LoadingSpinner />
          </div>
        }>{routes}</Suspense></main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
