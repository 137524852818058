import React from 'react';

const CourseCheckboxes = ({ courses, selectedItems, handleItemChange }) => {

  return (
    <div className="form-control course-checkboxes">
      {courses.map((courseObj, index) => {
        const courseName = courseObj.courseName; // Estrai il nome del corso dall'oggetto
        return (
          <label key={index}>
            <input
              type="checkbox"
              value={courseName}
              checked={selectedItems.includes(courseName)}
              onChange={() => handleItemChange(courseName)}
            />
            {courseName}
          </label>
        );
      })}
    </div>
  );
};


export default CourseCheckboxes;
