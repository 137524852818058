import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import Button from '../FormElements/Button';
import Modal from '../UIElements/Modal';
import { useDrawer } from '../../context/drawerContext';


import './NavLinks.css';


const NavLinks = props => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { closeDrawer } = useDrawer(); // Utilizza il hook useDrawer per accedere a closeDrawer
  const auth = useContext(AuthContext);

  const showHelpHandler = (e) => {
    closeDrawer(); // Chiude il SideDrawer prima di aprire il modal
    e.stopPropagation(); // Previene la propagazione dell'evento
    setShowConfirmModal(true);
  };

  const cancelHelpHandler = () => {
    setShowConfirmModal(false);
  };
  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/" exact>
          CANDIDATI
        </NavLink>
      </li>
      <li>
        <NavLink to="/faq" exact>
        FAQ
        </NavLink>
      </li>
      <li>
      <Button className="navlinksoverl button-no-border" onClick={(e) => showHelpHandler(e)}>HELP</Button>
      <Modal
  show={showConfirmModal}
  onCancel={cancelHelpHandler}
  header={props.address}
  contentClass="place-item__modal-content"
  footerClass="place-item__modal-actions"
  footer={<Button className="button-no-border" onClick={cancelHelpHandler}>CHIUDI</Button>}
>
  <div className="modal-body">
    <h2 className="modal-title">Problematiche tecniche o modifiche</h2>
    <p className="modal-text">
      Per cambi o modifiche o per problemi tecnici contattare il supporto alla mail 
      <a href="mailto:supporto.fad@foritgroup.it"> supporto.fad@foritgroup.it</a>
    </p>
  </div>
</Modal>
      </li>
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/downloads">DOWNLOAD</NavLink>
        </li>)}
        {auth.isLoggedIn && (
        <li>
          <NavLink to="/analytics">ANALYTICS</NavLink>
        </li>
      )}
      {/* {auth.isLoggedIn && (
        <li>
          <NavLink to="/places/new">MODIFICHE</NavLink>
        </li>
      )} */}
      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/auth">LOGIN</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <button onClick={auth.logout}>LOGOUT</button>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
