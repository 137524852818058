import React from 'react';

import Modal from './Modal';
import Button from '../FormElements/Button';

const ErrorModal = props => {
  // Utilizza "dangerouslySetInnerHTML" per impostare l'HTML
  // Assicurati che il contenuto HTML sia sanificato per evitare XSS
  const createMarkup = (htmlContent) => {
    return {__html: htmlContent};
  };

  return (
    <Modal
      onCancel={props.onClear}
      header="Attenzione!"
      show={!!props.error}
      footer={<Button onClick={props.onClear}>Okay</Button>}
    >
      {/* Renderizza l'HTML del messaggio di errore */}
      <p dangerouslySetInnerHTML={createMarkup(props.error)}></p>
    </Modal>
  );
};

export default ErrorModal;
